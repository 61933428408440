import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, NO_ERRORS_SCHEMA, LOCALE_ID, inject, provideAppInitializer, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/global/shared.module';
import { TokenInterceptorService } from './shared/services/token-interceptor.service';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CodeEditorModule } from '@ngstack/code-editor';
import { TerminalDialogComponent } from './shared/dialogs/terminal-dialog/terminal-dialog.component';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { MatDialogModule } from '@angular/material/dialog';

export function preloadUserLanguage(transloco: TranslocoService) {
	return function () {
		let lang = localStorage.getItem('plmAppV8_language');
		if (!lang) {
			// @ts-ignore
			const navlang = navigator.language || navigator.userLanguage;
			if (navlang === 'de-DE' || navlang === 'de-CH' || navlang === 'de-AT' || navlang === 'de') {
				lang = 'de';
			} else if (navlang === 'fr-FR' || navlang === 'fr') {
				lang = 'fr';
			} else {
				lang = 'en';
			}
		}
		transloco.setActiveLang(lang);
		localStorage.setItem('plmAppV8_language', lang);
		return transloco.load(lang).toPromise();
	};
}

@NgModule({
	declarations: [AppComponent, TerminalDialogComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		environment.production ? [] : AkitaNgDevtools.forRoot(),
		AkitaNgRouterStoreModule,
		TranslocoRootModule,
		FontAwesomeModule,
		MatDialogModule,
		SharedModule,
		CodeEditorModule.forRoot({
			baseUrl: 'assets/monaco',
			typingsWorkerUrl: 'assets/workers/typings-worker.js',
		}),
	],
	providers: [
		{
			provide: LOCALE_ID,
			useValue: 'de', // 'de' for Germany, 'fr' for France ...
		},
		DatePipe,
		{
			provide: NG_ENTITY_SERVICE_CONFIG,
			useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' },
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true,
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: preloadUserLanguage,
			deps: [TranslocoService, Sentry.TraceService],
			multi: true,
		},
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {}
